<template>
  <div>
    <base-template>
      <p class="code-send-success">{{ $t('login.smsSend') }} {{ dataForm.mobile }}</p>
      <div class="send-code-box">
        <code-input v-model="dataForm.code" @finish="validateCode">
          <template #button>
            <send-code-button ref="sendCodeButton" v-model="dataForm.code" module-type="change_pwd" unique-key="forgetPass" :mobile="dataForm.mobile" />
          </template>
        </code-input>
      </div>
      <p class="send-voice"><span @click="$refs.sendCodeButton.sendSms(2)">{{ $t('login.sendVoice') }}</span></p>
    </base-template>
  </div>
</template>

<script>
import CodeInput from '@/components/CodeInput'
import SendCodeButton from '@/components/SendCodeButton'

export default {
  components: { CodeInput, SendCodeButton },
  data() {
    return {
      dataForm: {
        mobile: this.$route.query.mobile,
        code: ''
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.sendCodeButton.sendSms(1)
    })
  },
  methods: {
    validateCode() {
      this.$nextTick(async() => {
        const valid = await this.$refs.sendCodeButton.checkNewsCode(this.dataForm.code)
        if (valid) {
          this.$router.push({
            path: '/setPassWord',
            query: {
              mobile: this.dataForm.mobile,
              forgetPass: 'forgetPass'
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.logo){
  padding-top: 70px;
}
.code-send-success{
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #002424;
  line-height: 32px;
  text-align: center;
  padding-top: 60px;
}
.send-voice{
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1761C5;
  line-height: 32px;
  text-align: center;
}
.send-voice{
  margin-top: 68px;
}
.send-code-box{
  margin-top: 96px;
  display: flex;
  justify-content: center;
}

.privacidad{
  position: fixed;
  bottom: 70px;
  left: 30px;
}
:deep(.main-content){
  background: #fff;
  .child-title{
    display: none;
  }
}
</style>
